import React, { SVGProps } from 'react';

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill='currentColor'
      className='svg-icon'
      {...props}
    >
      <path d="M16 16.75A7.75 7.75 0 108.25 9 7.76 7.76 0 0016 16.75zm0-14A6.25 6.25 0 119.75 9 6.257 6.257 0 0116 2.75zm14.41 27.09C28.907 23.163 23.027 18.25 16 18.25S3.093 23.163 1.609 29.741l-.019.099a.75.75 0 001.464.325l.001-.005C4.406 24.162 9.688 19.75 16 19.75s11.594 4.413 12.929 10.322l.017.089a.75.75 0 00.732.59h.001a.745.745 0 00.165-.019l-.005.001a.751.751 0 00.572-.898l.001.005z"></path>
    </svg>
);

export default SvgSearch;
