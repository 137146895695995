import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import CommonHeaderAccount from './CommonHeaderAccount';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';

const DashboardHeader = () => {
	return (
		<Header>
			<HeaderLeft>
				<div className='d-flex align-items-center gap-3'>
					<Dropdown className='d-none d-md-block' direction='down'>
						<DropdownToggle hasIcon={false}>
							<Icon
								className='cursor-pointer'
								icon='PlaylistAddCheck'
								size='2x'
								color='primary'
							/>
						</DropdownToggle>
						<DropdownMenu size='lg'>
							<DropdownItem isText={true} color='primary'>
								<div className='d-flex flex-column py-3'>
									<ul className='justify-content-start w-100'>
										<li>
											<div>Sales & Revenue</div>
										</li>
										<li>
											<div>Business Intelligence - Sales & Ravenue</div>
										</li>
										<li>
											<div>PMS Application</div>
										</li>
										<li>
											<div>HRIS</div>
										</li>
										<li>
											<div>Onboarding</div>
										</li>
									</ul>
									<div className='w-100 d-flex justify-content-end gap-2 pt-3'>
										Recently Visited Page
									</div>
								</div>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
					<Dropdown className='d-none d-md-block' direction='down'>
						<DropdownToggle hasIcon={false}>
							<Icon
								className='cursor-pointer'
								icon='Settings'
								size='2x'
								color='primary'
							/>
						</DropdownToggle>
						<DropdownMenu size='lg'>
							<DropdownItem isText={true} color='primary'>
								<div className='d-flex flex-column py-3'>
									<div className='d-flex justify-content-between align-items-start gap-4'>
										<div className='d-flex flex-column gap-2'>
											<div>1001 - Collapse Menu</div>
											<div>1002 - Open Dashboard</div>
											<div>1003 - Open Profile</div>
											<div>1004 - Next Page</div>
											<div>1005 - Previous Page</div>
										</div>
										<div className='d-flex flex-column gap-2'>
											<div>1001 - Collapse Menu</div>
											<div>1002 - Open Dashboard</div>
											<div>1003 - Open Profile</div>
											<div>1004 - Next Page</div>
											<div>1005 - Previous Page</div>
										</div>
									</div>
									<div className='w-100 d-flex justify-content-end gap-2 pt-3'>
										See All <Icon icon='ArrowRight' size='sm' color='primary' />
									</div>
								</div>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
					<Dropdown className='d-none d-md-block' direction='down'>
						<DropdownToggle hasIcon={false}>
							<Icon
								className='cursor-pointer'
								icon='ScreenShare'
								size='2x'
								color='primary'
							/>
						</DropdownToggle>
						<DropdownMenu size='lg'>
							<DropdownItem isText={true} color='primary'>
								<div className='d-flex flex-column py-3'>
									<div className='d-flex justify-content-between align-items-start gap-4'>
										<div className='d-flex flex-column gap-2'>
											<div>F1 - Collapse Menu</div>
											<div>F2 - Open Dashboard</div>
											<div>F3 - Open Profile</div>
											<div>F4 - Next Page</div>
											<div>F5 - Previous Page</div>
										</div>
										<div className='d-flex flex-column gap-2'>
											<div>F6 - Collapse Menu</div>
											<div>F7 - Open Dashboard</div>
											<div>F8 - Open Profile</div>
											<div>F9 - Next Page</div>
											<div>F10 - Previous Page</div>
										</div>
									</div>
									<div className='w-100 d-flex justify-content-end gap-2 pt-3'>
										See All <Icon icon='ArrowRight' size='sm' color='primary' />
									</div>
								</div>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
					<Search />
				</div>
			</HeaderLeft>
			<CommonHeaderRight afterChildren={<CommonHeaderAccount />} />
		</Header>
	);
};

export default DashboardHeader;
