import React, { useContext } from 'react';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Footer from '../../../layout/Footer/Footer';
import Logo from '../../../components/Logo';
import { useWindowSize } from 'react-use';
import ThemeContext from '../../../contexts/themeContext';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';

const DefaultFooter = () => {
	const { darkModeStatus } = useDarkMode();
	const { themeStatus } = useDarkMode();

	const windowsWidth = useWindowSize().width;
	const {
		asideStatus,
		setAsideStatus,
		leftMenuStatus,
		setLeftMenuStatus,
		rightMenuStatus,
		setRightMenuStatus,
	} = useContext(ThemeContext);

	return (
		<>
			{windowsWidth < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE) && (
				<div id='MobileMenu'>
					<div id='MobileMenuWrapper' className='m-auto'>
						<div className='row'>
							<div className='col text-center'>
								<Button
									aria-label='Toggle Aside'
									className='mobile-header-toggle'
									isLight={asideStatus}
									onClick={() => {
										setAsideStatus(!asideStatus);
										setLeftMenuStatus(false);
										setRightMenuStatus(false);
									}}>
									<Icon icon={'CustomHome'} size={'3x'} color={'secondary'} />
								</Button>
							</div>
							<div className='col text-center'>
								<Button
									aria-label='Toggle Left Menu'
									className='mobile-header-toggle'
									isLight={leftMenuStatus}
									onClick={() => {
										setAsideStatus(false);
										setLeftMenuStatus(!leftMenuStatus);
										setRightMenuStatus(false);
									}}>
									<Icon icon={'CustomSearch'} size={'3x'} color={'secondary'} />
								</Button>
							</div>

							<div className='col text-center'>
								<Dropdown direction='down'>
									<DropdownToggle hasIcon={false}>
										<Button>
											<Icon
												icon={'CustomShortcut'}
												size={'3x'}
												color={'secondary'}
											/>
										</Button>
									</DropdownToggle>
									<DropdownMenu size='lg'>
										<DropdownItem isText={true} color='secondary'>
											<div className='d-flex flex-column py-3'>
												<div className='d-flex justify-content-between align-items-start gap-4'>
													<div className='d-flex flex-column gap-2'>
														<div>1001 - Collapse Menu</div>
														<div>1002 - Open Dashboard</div>
														<div>1003 - Open Profile</div>
														<div>1004 - Next Page</div>
														<div>1005 - Previous Page</div>
													</div>
													<div className='d-flex flex-column gap-2'>
														<div>1001 - Collapse Menu</div>
														<div>1002 - Open Dashboard</div>
														<div>1003 - Open Profile</div>
														<div>1004 - Next Page</div>
														<div>1005 - Previous Page</div>
													</div>
												</div>
												<div className='w-100 d-flex justify-content-end gap-2 pt-3'>
													See All
													<Icon
														icon='ArrowRight'
														size='sm'
														color='primary'
													/>
												</div>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>

							<div className='col text-center'>
								<Button
									aria-label='Toggle Right Menu'
									className='mobile-header-toggle'
									isLight={rightMenuStatus}
									onClick={() => {
										setAsideStatus(false);
										setLeftMenuStatus(false);
										setRightMenuStatus(!rightMenuStatus);
									}}>
									<Icon
										icon={'CustomPersonSingle'}
										size={'3x'}
										color={'secondary'}
									/>
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}
			<Footer>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col m-auto'>
							<span className='fw-light'>Copyright © 2023</span>
						</div>
						<div className='col-auto'>
							<a
								href='https://wit.id'
								className={classNames('text-decoration-none', {
									'link-dark': !darkModeStatus,
									'link-light': darkModeStatus,
								})}>
								<Logo width={100} height={45} defaultLogo={true} />
							</a>
						</div>
					</div>
				</div>
			</Footer>
		</>
	);
};

export default DefaultFooter;
