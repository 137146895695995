import React, { FC, useCallback, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import { id } from 'date-fns/locale';
import Cookies from 'js-cookie';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	// if (isNewUser) {
	// 	return (
	// 		<>
	// 			<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
	// 			<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
	// 		</>
	// 	);
	// }
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const usernameCheck = (username: string) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username: string, password: string) => {
		return getUserDataWithUsername(username).password === password;
	};

	const formik = useFormik({
		enableReinitialize: true,
		// initialValues: {
		// 	loginUsername: USERS.JOHN.username,
		// 	loginPassword: USERS.JOHN.password,
		// },
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			if (usernameCheck(values.loginUsername)) {
				if (passwordCheck(values.loginUsername, values.loginPassword)) {
					if (setUser) {
						setUser(values.loginUsername);
					}

					handleOnClick();
					Cookies.set('userId', getUserDataWithUsername(formik.values.loginUsername).id);
					Cookies.set(
						'loginStatus',
						getUserDataWithUsername(formik.values.loginUsername).id,
					);
				} else {
					formik.setFieldError('loginPassword', 'Username and password do not match.');
				}
			}
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const handleContinue = () => {
		setIsLoading(true);
		setTimeout(() => {
			if (
				!Object.keys(USERS).find(
					(f) => USERS[f].username.toString() === formik.values.loginUsername,
				)
			) {
				formik.setFieldError('loginUsername', 'No such user found in the system.');
			} else {
				setSignInPassword(true);
			}
			setIsLoading(false);
		}, 1000);
	};

	const [eye, setEye] = useState(true);
	const [inputPasswordType, setinputPasswordType] = useState(false);

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			// className={classNames({ 'bg-purple': !singUpStatus, 'bg-light': singUpStatus })}
			className='bg-gradient-custom'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark bg-light' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width='auto' height='auto' defaultLogo={true} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-purple': darkModeStatus,
									})}>
									{/* <div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div> */}
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								{/* <Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> {USERS.JOHN.username}
										</div>
										<div className='col-12'>
											<strong>Password:</strong> {USERS.JOHN.password}
										</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									{/* {singUpStatus ? (
										<>
											<div className='col-12'>
												<FormGroup
													id='signup-email'
													isFloating
													label='Your email'>
													<Input type='email' autoComplete='email' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-name'
													isFloating
													label='Your name'>
													<Input autoComplete='given-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-surname'
													isFloating
													label='Your surname'>
													<Input autoComplete='family-name' />
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='signup-password'
													isFloating
													label='Password'>
													<Input
														type='password'
														autoComplete='password'
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													onClick={handleOnClick}>
													Sign Up
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Your email or username'>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Hi, {formik.values.loginUsername}.
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Password'
													className={classNames({
														'd-none': !signInPassword,
													})}>
													<Input
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='Looks good!'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='secondary'
													className='w-100 py-3 mb-5'
													onClick={formik.handleSubmit}>
													Sign In
												</Button>
											</div>
										</>
									)} */}

									{/* BEGIN :: Social Login */}
									{/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div>
										</>
									)} */}
									{/* END :: Social Login */}
									<>
										<div className='col-12 position-relative'>
											<FormGroup
												id='loginUsername'
												isFloating
												label='Your username'
												className={classNames({
													'd-none': signInPassword,
												})}>
												<Input
													autoComplete='username'
													value={formik.values.loginUsername}
													isTouched={formik.touched.loginUsername}
													invalidFeedback={formik.errors.loginUsername}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													onFocus={() => {
														formik.setErrors({});
													}}
												/>
											</FormGroup>
											{signInPassword && (
												<div className='text-center h4 mb-3 fw-bold'>
													Hi,{' '}
													{
														getUserDataWithUsername(
															formik.values.loginUsername,
														).name
													}
													.
												</div>
											)}
											<FormGroup
												id='loginPassword'
												isFloating
												label='Password'
												className={classNames({
													'd-none': !signInPassword,
												})}>
												<Input
													id='inputPassword'
													type={inputPasswordType ? 'text' : 'password'}
													autoComplete='current-password'
													value={formik.values.loginPassword}
													isTouched={formik.touched.loginPassword}
													invalidFeedback={formik.errors.loginPassword}
													validFeedback='Looks good!'
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</FormGroup>
											{signInPassword && (
												<Button
													id='buttonEye'
													className='position-absolute right-0 top-50'
													onClick={() => {
														setEye(!eye);
														setinputPasswordType(!inputPasswordType);
													}}>
													{eye ? (
														<svg
															id='eyeClosed'
															xmlns='http://www.w3.org/2000/svg'
															data-name='Layer 1'
															viewBox='0 0 122.88 53.37'
															width='2em'
															height='2em'>
															<path d='M1.05 7.37a4.49 4.49 0 116.88-5.76 114.61 114.61 0 0014.88 15C35.48 27 48.63 32.25 61.67 32.24s26.12-5.24 38.7-15.75a112.38 112.38 0 0014.57-14.85 4.48 4.48 0 016.92 5.69 129.09 129.09 0 01-10 10.85l5.91 5.42a4.47 4.47 0 11-6 6.6l-6.59-6a86.1 86.1 0 01-13.43 9.06l3.73 8A4.48 4.48 0 0187.35 45l-3.74-8a61.24 61.24 0 01-17.56 4v7.9a4.48 4.48 0 01-8.95 0V41a61.75 61.75 0 01-17.52-4l-3.76 8a4.48 4.48 0 11-8.11-3.79l3.74-8A88.78 88.78 0 0118 24.2l-6.55 6a4.47 4.47 0 11-6-6.6l5.83-5.34A130.63 130.63 0 011.05 7.37z'></path>
														</svg>
													) : (
														<svg
															id='eyeOpen'
															xmlns='http://www.w3.org/2000/svg'
															x='0'
															y='0'
															enableBackground='new 0 0 122.88 65.06'
															version='1.1'
															viewBox='0 0 122.88 65.06'
															xmlSpace='preserve'
															width='2em'
															height='2em'>
															<path d='M.95 30.01c2.92-3.53 5.98-6.74 9.15-9.63C24.44 7.33 41.46.36 59.01.01c17.51-.35 35.47 5.9 51.7 19.29 3.88 3.2 7.63 6.77 11.24 10.74a3.564 3.564 0 01.23 4.51c-4.13 5.83-8.88 10.82-14.07 14.96-12.99 10.37-28.77 15.47-44.76 15.55-15.93.07-32.06-4.86-45.8-14.57A82.228 82.228 0 01.77 34.64c-1.11-1.4-1-3.37.18-4.63zm60.49-3.55c.59 0 1.17.09 1.71.24-.46.5-.73 1.17-.73 1.9 0 1.56 1.26 2.82 2.82 2.82.77 0 1.46-.3 1.97-.8.2.6.3 1.24.3 1.9 0 3.35-2.72 6.07-6.07 6.07s-6.07-2.72-6.07-6.07c0-3.34 2.72-6.06 6.07-6.06zm0-15.64c5.99 0 11.42 2.43 15.35 6.36 3.93 3.93 6.36 9.35 6.36 15.35 0 5.99-2.43 11.42-6.36 15.35a21.628 21.628 0 01-15.35 6.36c-5.99 0-11.42-2.43-15.35-6.36a21.628 21.628 0 01-6.36-15.35c0-5.99 2.43-11.42 6.36-15.35 3.93-3.93 9.36-6.36 15.35-6.36zm10.45 11.26a14.746 14.746 0 00-10.45-4.33c-4.08 0-7.78 1.65-10.45 4.33a14.746 14.746 0 00-4.33 10.45c0 4.08 1.65 7.78 4.33 10.45 2.67 2.67 6.37 4.33 10.45 4.33 4.08 0 7.78-1.65 10.45-4.33 2.67-2.67 4.33-6.37 4.33-10.45 0-4.08-1.66-7.78-4.33-10.45zm-57 3.55a86.454 86.454 0 00-6.7 6.82c4.07 4.72 8.6 8.8 13.45 12.23 12.54 8.85 27.21 13.35 41.69 13.29 14.42-.07 28.65-4.67 40.37-14.02 4-3.19 7.7-6.94 11.03-11.25-2.79-2.91-5.63-5.54-8.51-7.92C91.33 12.51 75 6.79 59.15 7.1c-15.81.32-31.22 6.66-44.26 18.53z'></path>
														</svg>
													)}
												</Button>
											)}
										</div>
										<div className='col-12'>
											{!signInPassword ? (
												<Button
													color='secondary'
													className='w-100 py-3'
													isDisable={!formik.values.loginUsername}
													onClick={handleContinue}>
													{isLoading && (
														<Spinner isSmall inButton isGrow />
													)}
													Continue
												</Button>
											) : (
												<Button
													color='secondary'
													className='w-100 py-3'
													onClick={formik.handleSubmit}>
													Login
												</Button>
											)}
										</div>
									</>
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
