import React, { SVGProps } from 'react';

const customGraph = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
        width='1em'
        height='1em'
        className='svg-icon'
        {...props}
    >
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M5 7.75V7m3 .75V5.5m3 2.25V4m-6 9.75l3-3 3 3M1.25 1h13.5M2 1h12v9a.75.75 0 01-.75.75H2.75A.75.75 0 012 10V1z"
        ></path>
    </svg>
);

export default customGraph;
