import React, { SVGProps } from 'react';

const customProgramming = (props: SVGProps<SVGSVGElement>) => (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width='1em'
      height='1em'
      fill="none"
      viewBox="0 0 18 18"
      className='svg-icon'
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.698 1.5l.022 1.148A1.134 1.134 0 007.845 3.75h2.265c.623 0 1.125-.51 1.125-1.125V1.5"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12.75 12.75l-1.5 1.5 1.5 1.5M15 12.75l1.5 1.5-1.5 1.5"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M9.75 16.5H6c-2.625 0-3.75-1.5-3.75-3.75v-7.5C2.25 3 3.375 1.5 6 1.5h6c2.625 0 3.75 1.5 3.75 3.75v5.25"
      ></path>
    </svg>
);
export default customProgramming;
