import React, { SVGProps } from 'react';

const customFolderOpen = (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 18 18"
      className='svg-icon'
      {...props}
    >
      <path
        fill="#fff"
        d="M17.085 7.778A.75.75 0 0016.5 7.5H15v-.75a2.25 2.25 0 00-2.25-2.25H8.04l-.24-.75a2.25 2.25 0 00-2.13-1.5H3A2.25 2.25 0 00.75 4.5v9A2.25 2.25 0 003 15.75h10.8a2.25 2.25 0 002.19-1.762l1.26-5.573a.75.75 0 00-.165-.637zM4.027 13.665a.75.75 0 01-.75.585H3a.75.75 0 01-.75-.75v-9A.75.75 0 013 3.75h2.67a.75.75 0 01.75.51l.405 1.23A.75.75 0 007.5 6h5.25a.75.75 0 01.75.75v.75H6a.75.75 0 00-.75.585l-1.223 5.58zm10.5 0a.75.75 0 01-.75.585h-8.37c.04-.084.067-.172.083-.262L6.6 9h9l-1.072 4.665z"
      ></path>
    </svg>
);

export default customFolderOpen;
