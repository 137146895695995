import React, { SVGProps } from 'react';

const customStatusUp = (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 18 18"
      className='svg-icon'
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M5.16 13.613V12.06M9 13.613v-3.105M12.84 13.613V8.948M12.84 4.388l-.345.405a14.161 14.161 0 01-7.335 4.53"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.643 4.388h2.197v2.19"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.75 16.5h4.5c3.75 0 5.25-1.5 5.25-5.25v-4.5C16.5 3 15 1.5 11.25 1.5h-4.5C3 1.5 1.5 3 1.5 6.75v4.5C1.5 15 3 16.5 6.75 16.5z"
      ></path>
    </svg>
);

export default customStatusUp;
