import React, { SVGProps } from 'react';

const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
        width='1em'
        height='1em'
        className='svg-icon'
        {...props}
    >
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M12.75 13h3.75v-1.5a2.25 2.25 0 00-4.017-1.393M12.75 13h-7.5m7.5 0v-1.5c0-.492-.095-.962-.267-1.393M5.25 13H1.5v-1.5a2.25 2.25 0 014.017-1.393M5.25 13v-1.5c0-.492.095-.962.267-1.393m0 0a3.751 3.751 0 016.966 0M11.25 3.25a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm4.5 2.25a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-10.5 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        ></path>
    </svg>
);

export default SvgPerson;
