import React, { SVGProps } from 'react';

const customChart = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
        width='1em'
        height='1em'
        className='svg-icon'
        {...props}
    >
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M10 4v6M7 6.25V10M4 8.5V10m-1.5 3h9a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0011.5 1h-9A1.5 1.5 0 001 2.5v9A1.5 1.5 0 002.5 13z"
        ></path>
    </svg>
);

export default customChart;
