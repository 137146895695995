import { TColor } from '../../type/color-type';

export interface IServiceProps {
	name: string;
	icon: string;
	color: TColor;
}

const surfing: IServiceProps = {
	name: 'Executive',
	icon: 'RoomService',
	color: 'info',
};
const kiteSurfing: IServiceProps = {
	name: 'President',
	icon: 'RoomService',
	color: 'danger',
};
const tennis: IServiceProps = {
	name: 'Villa',
	icon: 'RoomService',
	color: 'success',
};
const kayaking: IServiceProps = {
	name: 'Adjoining',
	icon: 'RoomService',
	color: 'info',
};
const handball: IServiceProps = {
	name: 'Cabana',
	icon: 'RoomService',
	color: 'warning',
};
const iceSkating: IServiceProps = {
	name: 'Murphy',
	icon: 'RoomService',
	color: 'info',
};
const snowboarding: IServiceProps = {
	name: 'Connecting',
	icon: 'RoomService',
	color: 'warning',
};
const volleyball: IServiceProps = {
	name: 'Junior',
	icon: 'RoomService',
	color: 'warning',
};
const cricket: IServiceProps = {
	name: 'Studio',
	icon: 'RoomService',
	color: 'success',
};
const yoga: IServiceProps = {
	name: 'Holywood Twin',
	icon: 'RoomService',
	color: 'success',
};
const hiking: IServiceProps = {
	name: 'King',
	icon: 'RoomService',
	color: 'danger',
};
const football: IServiceProps = {
	name: 'Queen',
	icon: 'RoomService',
	color: 'success',
};

const SERVICES: { [key: string]: IServiceProps } = {
	SURFING: surfing,
	KITE_SURFING: kiteSurfing,
	TENNIS: tennis,
	KAYAKING: kayaking,
	HANDBALL: handball,
	ICE_SKATING: iceSkating,
	SNOWBOARDING: snowboarding,
	VOLLEYBALL: volleyball,
	CRICKET: cricket,
	YOGA: yoga,
	HIKING: hiking,
	FOOTBALL: football,
};

export function getServiceDataWithServiceName(serviceName: string) {
	return SERVICES[
		// @ts-ignore
		Object.keys(SERVICES).filter((f) => SERVICES[f].name.toString() === serviceName)
	];
}

export default SERVICES;
