import React, { SVGProps } from 'react';

const SvgNotifications = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d="M15 17.5H20L18.5951 16.0951C18.2141 15.7141 18 15.1973 18 14.6585V11.5C18 8.88757 16.3304 6.66509 14 5.84142V5.5C14 4.39543 13.1046 3.5 12 3.5C10.8954 3.5 10 4.39543 10 5.5V5.84142C7.66962 6.66509 6 8.88757 6 11.5V14.6585C6 15.1973 5.78595 15.7141 5.40493 16.0951L4 17.5H9M15 17.5V18.5C15 20.1569 13.6569 21.5 12 21.5C10.3431 21.5 9 20.1569 9 18.5V17.5M15 17.5H9" stroke="#858585" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};

export default SvgNotifications;
