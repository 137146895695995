import React, { SVGProps } from 'react';

const SvgLightMode = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d="M12 3.5V4.5M12 20.5V21.5M21 12.5H20M4 12.5H3M18.364 18.864L17.6569 18.1569M6.34315 6.84315L5.63604 6.13604M18.364 6.13609L17.6569 6.8432M6.3432 18.1569L5.63609 18.864M16 12.5C16 14.7091 14.2091 16.5 12 16.5C9.79086 16.5 8 14.7091 8 12.5C8 10.2909 9.79086 8.5 12 8.5C14.2091 8.5 16 10.2909 16 12.5Z" stroke="#858585" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};

export default SvgLightMode;
