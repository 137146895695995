import React, { SVGProps } from 'react';

const SvgShortcut = (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill='currentColor'
      className='svg-icon'
      {...props}
    >
      <path d="M22 14v8H2V2h12v1H3v18h18v-7zm-4.354-9.646L20.258 7h-4.751a6.478 6.478 0 00-6.5 6.465V17H10v-3.535A5.482 5.482 0 0115.507 8h4.821l-2.682 2.646.707.707L22.207 7.5l-3.853-3.854z"></path>
      <path fill="none" d="M0 0h24v24H0z"></path>
    </svg>
);

export default SvgShortcut;
