import React, { SVGProps } from 'react';

const customSettings = (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 18 18"
      className='svg-icon'
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.75 16.5h4.5c3.75 0 5.25-1.5 5.25-5.25v-4.5C16.5 3 15 1.5 11.25 1.5h-4.5C3 1.5 1.5 3 1.5 6.75v4.5C1.5 15 3 16.5 6.75 16.5z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M11.678 13.875V10.95M11.678 5.588V4.125M11.678 9.488a1.95 1.95 0 100-3.9 1.95 1.95 0 000 3.9zM6.323 13.875v-1.463M6.323 7.05V4.125M6.323 12.412a1.95 1.95 0 100-3.9 1.95 1.95 0 000 3.9z"
      ></path>
    </svg>
);

export default customSettings;
