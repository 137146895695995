import React, { SVGProps } from 'react';

const customCard = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width='1em'
        height='1em'
        className='svg-icon'
        {...props}
        fill="none"
        viewBox="0 0 16 12"
    >
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M1.25 4.5h13.5M4.25 8.25H5m3 0h.75m-5.25 3h9A2.25 2.25 0 0014.75 9V3A2.25 2.25 0 0012.5.75h-9A2.25 2.25 0 001.25 3v6a2.25 2.25 0 002.25 2.25z"
        ></path>
    </svg>
);

export default customCard;
