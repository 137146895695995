import React, { useState, useContext, ReactNode, useEffect } from 'react';
import OffCanvas, { OffCanvasBody, OffCanvasHeader } from '../../../components/bootstrap/OffCanvas';
import { getUserDataWithId } from '../../../common/data/userDummyData';
import { NavigationLine } from '../../../layout/Navigation/Navigation';
import { useNavigate } from 'react-router-dom';
import useNavigationItemHandle from '../../../hooks/useNavigationItemHandle';
import AuthContext from '../../../contexts/authContext';
import Avatar from '../../../components/Avatar';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/icon/Icon';
import { demoPagesMenu } from '../../../menu';
import useDarkMode from '../../../hooks/useDarkMode';
import Cookies from 'js-cookie';

const CommonHeaderAccount = () => {
	const [state, setState] = useState<boolean>(false);
	const { userData, setUser } = useContext(AuthContext);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();

	const { t } = useTranslation(['translation', 'menu']);

	const loginStatus = Cookies.get('loginStatus');
	const id = Cookies.get('userId') ?? '1';
	const data = getUserDataWithId(id);

	useEffect(() => {
		if (loginStatus === undefined && id === undefined) {
			navigate('/auth-pages/login');
		}
	}, [navigate, loginStatus, id]);

	return (
		<>
			<div
				className='col d-flex align-items-center cursor-pointer justify-content-end'
				onClick={() => setState(!state)}
				role='presentation'>
				<div className='ms-3'>
					<div className='text-start'>
						<div className='fw-bold fs-6 mb-0'>{`${data.name} ${data.surname}`}</div>
						<div className='text-muted'>
							<small>{data.position}</small>
						</div>
					</div>
				</div>
				<div className='position-relative ms-3'>
					<Avatar srcSet={data.srcSet} src={data.src} size={48} color={data.color} />
					<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
						<span className='visually-hidden'>Online user</span>
					</span>
				</div>
			</div>
			<OffCanvas
				id='chat'
				isOpen={state}
				setOpen={setState}
				placement='end'
				isModalStyle
				isBackdrop={false}
				isBodyScroll>
				<OffCanvasHeader setOpen={setState} className='fs-5'>
					<div
						className='col d-flex align-items-center cursor-pointer'
						onClick={() => setState(!state)}
						role='presentation'>
						<div className='me-3'>
							<div className='text-end'>
								<div className='fw-bold fs-6 mb-0'>
									{`${data.name} ${data.surname}`}
								</div>
								<div className='text-muted'>
									<small>{data.position}</small>
								</div>
							</div>
						</div>
						<div className='position-relative'>
							<Avatar
								srcSet={data.srcSet}
								src={data.src}
								size={48}
								color={data.color}
							/>
							<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
								<span className='visually-hidden'>Online user</span>
							</span>
						</div>
					</div>
				</OffCanvasHeader>
				<OffCanvasBody>
					<nav aria-label='aside-bottom-user-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() =>
									navigate(
										`../${demoPagesMenu.appointment.subMenu.employeeID.path}/${userData?.id}`,
										// @ts-ignore
										handleItem(),
									)
								}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='AccountBox' className='navigation-icon' />
										<span className='navigation-text'>
											{t('menu:Profile') as ReactNode}
										</span>
									</span>
								</span>
							</div>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDarkModeStatus(!darkModeStatus);
									handleItem();
								}}></div>
						</div>
					</nav>
					<NavigationLine />
					<nav aria-label='aside-bottom-user-menu-2'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									if (setUser) {
										setUser('');
									}
									navigate(`../${demoPagesMenu.login.path}`);
									Cookies.remove('loginStatus', { sameSite: 'Lax' });
									Cookies.remove('userId', { sameSite: 'Lax' });
								}}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='Logout' className='navigation-icon' />
										<span className='navigation-text'>
											{t('menu:Logout') as ReactNode}
										</span>
									</span>
								</span>
							</div>
						</div>
					</nav>
				</OffCanvasBody>
			</OffCanvas>
		</>
	);
};

export default CommonHeaderAccount;
