import React, { SVGProps } from 'react';

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill='currentColor'
      className='svg-icon'
      {...props}
    >
      <path d="M30.531 29.469L20.078 19.016a10.647 10.647 0 002.58-6.97c0-5.912-4.793-10.704-10.704-10.704S1.25 6.135 1.25 12.046c0 5.912 4.793 10.704 10.704 10.704h.048H12a10.62 10.62 0 007.027-2.681l-.011.01 10.453 10.453a.75.75 0 101.062-1.062zM2.75 12c0-5.109 4.141-9.25 9.25-9.25s9.25 4.141 9.25 9.25A9.25 9.25 0 0112 21.25a9.26 9.26 0 01-9.25-9.249V12z"></path>
    </svg>
);

export default SvgSearch;
