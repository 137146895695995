import React, { SVGProps } from 'react';

const customBriefCase = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
        width='1em'
        height='1em'
        className='svg-icon'
        {...props}
    >
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M14.75 9.942A17.95 17.95 0 018 11.25a17.95 17.95 0 01-6.75-1.308M11 4.5V3a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 005 3v1.5M8 9h.008M2.75 15h10.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H2.75A1.5 1.5 0 001.25 6v7.5a1.5 1.5 0 001.5 1.5z"
        ></path>
    </svg>
);

export default customBriefCase;
