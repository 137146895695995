import React, { SVGProps } from 'react';

const customBoard = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
        width='1em'
        height='1em'
        className='svg-icon'
        {...props}
    >
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M3.75 2.75h-1.5a1.5 1.5 0 00-1.5 1.5v9a1.5 1.5 0 001.5 1.5h7.5a1.5 1.5 0 001.5-1.5v-9a1.5 1.5 0 00-1.5-1.5h-1.5m-4.5 0a1.5 1.5 0 001.5 1.5h1.5a1.5 1.5 0 001.5-1.5m-4.5 0a1.5 1.5 0 011.5-1.5h1.5a1.5 1.5 0 011.5 1.5"
        ></path>
    </svg>
);

export default customBoard;
