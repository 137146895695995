import React, { SVGProps } from 'react';

const SvgFullscreen = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='M0 0h24v24H0V0z' fill='none' />
			<path d="M4 8.5V4.5M4 4.5H8M4 4.5L9 9.5M20 8.5V4.5M20 4.5H16M20 4.5L15 9.5M4 16.5V20.5M4 20.5H8M4 20.5L9 15.5M20 20.5L15 15.5M20 20.5V16.5M20 20.5H16" stroke="#858585" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
};


export default SvgFullscreen;
